<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: `/routerProjectManagement/projectManagementList` }">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{$route.query.id ? '编辑' : '添加'}}项目</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <addForm ref="addForm" :detailData="enquiryDetail" />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <!-- <el-button size="mini"
        type="primary"
        @click="handleReset"
        plain
        icon="el-icon-refresh-left">重置</el-button> -->
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-circle-check"
        @click="handleSubmitEnquiry"
        >提交</el-button
      >
      <!-- <el-button size="mini" type="warning" icon="el-icon-document">生成投保单</el-button> -->
    </div>
    <DragMultipleUpload
      :show.sync="showUpload" :fileSzie="10"
      :dataEcho="dataEcho"
      @submitData="handleSubmitAttachment"
    ></DragMultipleUpload>
  </div>
</template>

<script>
import addForm from "@/views/routerProjectManagement/components/ProjectChildrenList/components/form.vue";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { hasPerms, dataPermissions } from "@/utils/auth";
import {
  newSaveProject
} from "@/api/basicData";
import { addProject, getProjectDetail } from "@/api/project";
import { newRiskProjectDetail } from "@/api/basicData";

export default {
  components: { addForm, DragMultipleUpload },
  data() {
    return {
      enquiryDetail: {},
      originalPolicyDetail: {},
      showUpload: false,
      dataEcho: {},
      attachmentData: {},
    };
  },
  created() {
    this.$route.query && this.$route.query.id ? this.getDetail() : "";
  },
  activated() {
    // this.$route.query && this.$route.query.id ? this.getDetail() : "";
  },
  methods: {
    getDetail() {
      newRiskProjectDetail(this.$route.query.id)
        .then((res) => {
          if (
            res.data.fileList &&
            res.data.fileList.records &&
            res.data.fileList.records.length
          ) {
            res.data.fileList = res.data.fileList.records.map((val) => {
              return {
                fileName: val.attName,
                fileType: val.attType,
                attSize: 999,
                url: val.visitUrl,
              };
            });
          } else {
            res.data.fileList = [];
          }
          this.enquiryDetail = JSON.parse(
            JSON.stringify({ ...this.enquiryDetail, ...res.data })
          );
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSubmitAttachment(e) {
      let attachment = {};
      let attachmentType = [];
      let qtInd = 1;
      e.map((val) => {
        if (val.type) {
          attachmentType.push(val.type + "-" + val.selectValue);
          attachment[val.type + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
        } else {
          attachmentType.push("qt" + qtInd + "-" + val.selectValue);
          attachment["qt" + qtInd + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
          qtInd++;
        }
      });
      this.attachmentData = {
        attachments: attachment,
        attachmentType: attachmentType.toString(),
      };
      this.showUpload = false;
    },
    async handleSubmitEnquiry(type) {
      let data = await this.$refs.addForm.handleSubmit(type);
      newSaveProject({ ...data }).then((res) => {
        this.handleBack();
        this.$message.success(res.mes || "保存成功");
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleReset() {
      this.attachmentData = {};
      this.$refs.addForm.handleReset();
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
