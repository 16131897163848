<template>
  <div class="el-collapse-box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      inline
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      class="el-collapse-box"
    >
      <AssociationScroll>
        <AssociationScrollView title="项目信息" name="0">
          <div class="container container-box">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input v-model="ruleForm.projectName"></el-input>
            </el-form-item>
            <el-form-item label="主项目：" prop="parentId">
              <el-select
                v-model="ruleForm.parentId"
                filterable
                
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectItems"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                ></el-option>
              </el-select>
              <!-- @change="
                  changeSelect(
                    $event,
                    projectItems,
                    'projectTypeValue',
                    ruleForm
                  )
                " -->
            </el-form-item>
            <el-form-item label="项目负责人：" prop="projectLeaderId">
              <EmployeeSelect isChangeTenantId
                v-model="ruleForm.projectLeaderId" :placeholderName="ruleForm.projectLeader" 
                @change="handleChangeSalesman"
                placeholder="请选择"
              />

              <!-- @change="handleChangeSalesman" -->
              <!-- @change="changeValidateField('salesmanId')" -->
            </el-form-item>
            <el-form-item label="所属客户：" prop="customerId">
              <div @click="isAddCustomersDialog = true" style="height:32px;border-radius: 4px;border: 1px solid #DCDFE6;padding:0 15px;line-height: 32px;color:#606266;">
                {{ ruleForm.customer || '请选择' }}
              </div>
              <!-- <EmployeeSelect isChangeTenantId
                v-model="ruleForm.customerId"
                placeholder="请选择"
              /> -->
                <!-- <el-select
                  v-model="ruleForm.customerId"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in projectUserList"
                    :key="item.id"
                    :label="item.account"
                    :value="item.id"
                  ></el-option>
                </el-select> -->
              <!-- @change="handleChangeSalesman" -->
              <!-- @change="changeValidateField('salesmanId')" -->
            </el-form-item>
            <el-form-item label="已出险未报案金额："
              prop="denyAmount">
                <el-input v-model="ruleForm.denyAmount" @input="input_num($event, ruleForm, 'denyAmount', 99999999999)"></el-input>
            </el-form-item>
            <el-form-item label="启用状态：" prop="enable">
              <el-radio-group v-model="ruleForm.enable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序："
              prop="order">
                <el-input v-model="ruleForm.order" @input="input_num($event, ruleForm, 'order', 99999999999, 0)"></el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="销售指标" name="2">
          <div class="container container-box">
            <TargetTabs :compatibleReadOnly="true" :children="true" v-model="ruleForm.targetInfo"></TargetTabs>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="项目保单" name="3">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isAssociationDialog = true"
                >绑定保单</el-button
              >
            </div>
            <dyTable
              :formData="detailData"
              ref="policy"
              :tableApi="projectPolicyListPage" :isTraditional="true" @changeList="e => ruleForm.policyInfoList = e"
              tableType="policy"
              :tableData="ruleForm.policyInfoList"
              border
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="policyNo" label="保单号" width="194">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleGoPath(scope.row)"
                    style="text-decoration: underline"
                    >{{ scope.row.policyNo }}</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column prop="status" label="保单状态" width="96">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status == 2 || scope.row.state == 2">保障中</el-tag>
                  <el-tag type="warning" v-else-if="scope.row.status == 1 || scope.row.state == 1"
                    >未生效</el-tag
                  >
                  <el-tag type="info" v-else>{{ scope.row.statusName || scope.row.stateName || '已过期' }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalPremium"
                label="总保费(元)"
                width="108"
              >
              </el-table-column>
              <el-table-column prop="policyHolder" label="投保人" width="90">
                <template slot-scope="scope">
                  {{ scope.row.policyHolder || scope.row.policyHolderName }}
                </template>
              </el-table-column>
              <el-table-column prop="insured" label="被保险人" width="90">
                <template slot-scope="scope">
                  {{ scope.row.insured || scope.row.insuredName }}
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceTypeName"
                label="险种名称"
                width="112"
              >
              </el-table-column>
              <el-table-column
                prop="insuranceCompanyName"
                label="保险公司"
                width="132"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.insuranceCompanyName ||
                    scope.row.primaryInsuranceCompanyName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceBranchName"
                label="分支机构"
                width="118"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.insuranceBranchName ||
                    scope.row.primaryInsuranceBranchName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="deviceTypeName"
                label="设备类型"
                width="90"
              >
              </el-table-column>
              <el-table-column prop="frameNo" label="车架号" width="80">
              </el-table-column>
              <el-table-column
                prop="salesmanDeptName"
                label="所属部门"
                width="156"
              >
              </el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="handleGoPath(scope.row)"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="机械设备" name="4">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isGroupDialog = true"
                >绑定设备</el-button
              >
            </div>
            <dyTable :isTraditional="true" @changeList="e => ruleForm.vehicleList = e"
              :tableData="ruleForm.vehicleList"
              :formData="detailData"
              :tableApi="projectDevicePage"
              tableType="vehicle"
              ref="vehicle"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="deviceName"
                label="设备名称"
              ></el-table-column>
              <el-table-column
                prop="groupName"
                label="所属车组"
              ></el-table-column>
              <el-table-column prop="frameNo" label="车架号"></el-table-column>
              <el-table-column
                prop="categoryName"
                label="机械种类"
              ></el-table-column>
              <el-table-column
                prop="typeName"
                label="设备类型"
              ></el-table-column>
              <el-table-column
                prop="brandName"
                label="设备品牌"
              ></el-table-column>
              <el-table-column
                prop="terminalNo"
                label="终端号"
              ></el-table-column>
              <el-table-column prop="jsName" label="绑定机手"></el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
              ></el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="handleGoPath(scope.row, '/SCDetails')"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="查看用户" name="5">
          <div class="container container-box">
            <div>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="small"
                plain
                @click="$refs.bladeUser.refresh()"
              ></el-button>
              <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                @click="isAddUserDialog = true"
                >添加用户</el-button
              >
            </div>
            <dyTable
              :tableData="ruleForm.bladeUserList"
              ref="bladeUser"
              :formData="detailData"
              :tableApi="projectViewUserPageApi"
              border
              style="width: 100%"
            >
              <el-table-column prop="index" label="序号" width="52">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="tenantName"
                label="所属租户"
              ></el-table-column>
              <el-table-column property="account" label="登录账号">
              </el-table-column>
              <el-table-column property="nickname" label="昵称">
              </el-table-column>
              <el-table-column property="name" label="姓名"> </el-table-column>
              <el-table-column property="phone" label="手机号">
              </el-table-column>
              <el-table-column
                prop="date"
                label="操作"
                fixed="right"
                width="136"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="color: #f64a2d"
                    @click="
                      handleDelPolicy(scope.$index, scope.row, 'bladeUserList')
                    "
                    >移除</el-button
                  >
                </template>
              </el-table-column>
            </dyTable>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    
    <addPolicyDialog
      v-model="isAssociationDialog" :projectId="detailData.id || ''"
      :policyIds="ruleForm.policyProjectIds || []"
      @submit="handleAddPolicyProject"
    />
    <AddRiskDialog
      v-model="isAddRiskDialog"
      :riskEditData="riskEditData"
      @submit="handleAddRisk"
    />
    <TrainingReviewDialog
      :show.sync="trainingReviewVisible"
      :id="trid"
    ></TrainingReviewDialog>
    <addGroupDialog
      v-model="isGroupDialog"
      :idsList="ruleForm.vehicleGroupIds || []" :projectId="detailData.id || ''" :nameList="ruleForm.vehicleGroupNames || []"
      @submit="handleAddGroup"
    />
    <addCustomersDialog
      v-model="isAddCustomersDialog" isIndividual
      :defaultList="ruleForm.customerId"
      @submit="handleAddCustomers"
    />
    <addUserDialog
      v-model="isAddUserDialog"
      :defaultList="ruleForm.bladeUserList"
      @submit="handleAddUser"
    />
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import FormListUpload from "@/components/FormListUpload/index.vue";
import TargetTabs from "@/views/routerProjectManagement/components/ProjectChildrenList/components/TargetTabs.vue";
import SelectPolicy from "@/views/routerProjectManagement/components/ProjectList/components/SelectPolicy.vue";
import AddRiskDialog from "@/views/routerProjectManagement/components/ProjectList/components/AddRiskDialog.vue";
import addPolicyDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addPolicyDialog.vue";
import addCustomersDialog from "@/views/routerProjectManagement/components/ProjectChildrenList/components/from/addCustomersDialog.vue";
import addUserDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addUserDialog.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import addGroupDialog from "@/views/routerProjectManagement/components/ProjectList/components/from/addGroupDialog.vue";
import dyTable from "@/views/routerProjectManagement/components/ProjectList/components/from/dyTable.vue";

import { isCardNo, phoneFun, uploadFile, fileToBase64 } from "@/utils/index";
import {
  pageProjectList, getProjectDetail
} from "@/api/project";
import {
  projectPolicyList,
  projectDeviceList,
  projectDevicePage,
  projectPolicyListPage,
  newRiskProject,
  bindVehicleGroup,
  bindPolicyProject,
  getProjectUserList,
  projectViewUserPage,
  addViewUser,
  delProjectCustomer,
  delViewUser
} from "@/api/basicData";
import { getUserByCharge, agentList } from "@/api/SystemData";
import VDistpicker from "v-distpicker";
import dayjs from "dayjs";
import TrainingReviewDialog from "@/views/RiskDecrement/riskHomeManagement/components/TrainingReviewDialog.vue";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailData: {
      handler(v) {
        this.ruleForm = JSON.parse(JSON.stringify({ ...this.ruleForm, ...v }));
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入密码"));
      } else {
        // this.$refs.ruleForm.validateField('checkPass');
        callback();
      }
    };
    return {
      trid: "",
      trainingReviewVisible: false,
      policyTableData: [],
      isAssociationDialog: false,
      CXchannel: [],
      riskTableData: [],
      isAddRiskDialog: false,
      riskEditData: {},
      projectRiskIds: [],
      ruleForm: {
        projectName: "",
        signingDate: "",
        remark: "",
        createTime: null,
        updateTime: null,
        createUser: null,
        createUserName: null,
        parentId: "",
        organPath: "",
        projectSequence: "",
        vehicleList: [],
        bladeUserList: [],
        vehicleGroupIds: [],
        vehicleGroupNames: [],
        parentId: "",
        targetMoney: "",
        targetCompensate: "",
        incomeMoney: "",
        incomeDisburse: "",
        compensateTargetRate: "",
        enable: true,
        order: 0,
        denyAmount: '',
        projectLeaderId: '',
        projectLeader: '',
        leaderDeptId: '',
        leaderDeptName: '',
        customerId: '',
        affiliateUserId: "",
        affiliateUserName: "",
        fileList: [],
        policyInfoList: [],
        policyProjectIds: [],
        riskInfoList: [],
        targetInfo: [
          {
            year: 2024,
            yearTargetMoney: "",
            yearTargetCompensate: "",
            yearTargetIncrease: "",
            targetMthInfoList: [
              {
                month: 1,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 2,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 3,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 4,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 5,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 6,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 7,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 8,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 9,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 10,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 11,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 12,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
            ],
          },
          {
            year: 2023,
            yearTargetMoney: "",
            yearTargetCompensate: "",
            yearTargetIncrease: "",
            targetMthInfoList: [
              {
                month: 1,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 2,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 3,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 4,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 5,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 6,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 7,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 8,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 9,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 10,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 11,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 12,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
            ],
          },
          {
            year: 2022,
            yearTargetMoney: "",
            yearTargetCompensate: "",
            yearTargetIncrease: "",
            targetMthInfoList: [
              {
                month: 1,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 2,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 3,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 4,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 5,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 6,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 7,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 8,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 9,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 10,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 11,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
              {
                month: 12,
                monthTargetMoney: "",
                monthTargetCompensate: "",
                monthTargetIncrease: "",
                remark: "",
              },
            ],
          },
        ],
      },
      rules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        parentId: [
          { required: true, message: "请选择主项目", trigger: "change" },
        ],
        productType: [
          { required: true, message: "请选择产品线", trigger: "change" },
        ],
        targetMoney: [
          { required: true, message: "请输入目标保费", trigger: "change" },
        ],
        targetCompensate: [
          { required: true, message: "请输入目标赔付率", trigger: "change" },
        ],
        projectLeaderId: [
          { required: true, message: "请选择项目负责人", trigger: "change" },
        ],
        // customerId: [
        //   { required: true, message: "请选择所属客户", trigger: "change" },
        // ],
        enable: [{ required: true, message: "请选择状态", trigger: "change" }],
        performanceTypeName: [{ required: true, message: "请选择绩效类型", trigger: "change" }],
      },
      projectItems: [],
      projectLines: [],
      performanceType: [],
      isGroupDialog: false,
      getProjectDetail,
      projectDevicePage,
      projectPolicyListPage,
      projectViewUserPageApi: projectViewUserPage,
      isUseApi: true,
      projectUserList: [],
      isAddCustomersDialog: false,
      isAddUserDialog: false,
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    VDistpicker,
    EmployeeSelect,
    FormListUpload,
    TargetTabs,
    SelectPolicy,
    AddRiskDialog,
    TrainingReviewDialog,
    addPolicyDialog,
    addGroupDialog,
    dyTable,
    addCustomersDialog,
    addUserDialog,
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    handleGoPath(e) {
      this.$router.push({
        path:
          "/routerPolicyManagement/ConstructionMachinery/policyDetails/" +
          (e.policyId || e.id),
      });
    },

    async init() {
      newRiskProject({
        index: 1,
        size: 9999,
        bladeUserId: this.$store.state.userInfo.MJUserId,
        projectLevel: -1
      }).then(res=>{
        this.projectItems = res.data.records;
      })
      const {data,code} = await getProjectUserList({
        index: 1,
        size: 99999,
        searchKeyWord: "",
        tenantId: this.$store.state.tenantId || 'KEHU'
      });
      if (code == 200 && data && data.records) {
        this.projectUserList = data.records;
      }
      // 证件类型identCardType 保险公司（insuranceCompany）、设备品牌（vehicleBrand）、设备类型（vehicleType）主险种编码：primaryInsuranceType 附加险种编码：attachInsuranceType 投保门店applicantStore 投保方式applicantMode 投保类型applicantType 投保方式applicantMode 险种类型FCXInsCategory 职业身份JobCategory
      // dictionaryBatch({
      //   codes: "projectItems,projectLines,performanceType",
      // }).then((res) => {
      //   if (res && res.data) {
      //     const { projectItems, projectLines, performanceType } = res.data;
      //     this.projectItems = projectItems || [];
      //     this.projectLines = projectLines || [];
      //     this.performanceType = performanceType || [];
      //   }
      // });
    },
    handleSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
            // if (ruleForm.fileList && ruleForm.fileList.length) {
            //   ruleForm.fileList = ruleForm.fileList.map((val) => {
            //     return {
            //       attName: val.fileName,
            //       attType: val.fileType,
            //       attSize: 999,
            //       visitUrl: val.url,
            //     };
            //   });
            // }
            if (ruleForm.policyInfoList && ruleForm.policyInfoList.length) {
              ruleForm.policyInfoList = ruleForm.policyInfoList.map((val) => {
                return {
                  id: val.id,
                };
              });
            }
            // if (this.projectRiskIds && this.projectRiskIds.length) {
            //   ruleForm.projectRiskIds = this.projectRiskIds;
            // }
            let findInd = ruleForm.targetInfo.findIndex(
              (val) =>
                val.year == "2024" &&
                (val.yearTargetMoney === "")
            );
            console.log(ruleForm.targetInfo);
            if (findInd > -1) {
              this.$message.error(
                `请填写销售指标${ruleForm.targetInfo[findInd].year}年度参数`
              );
              return false;
            }
            ruleForm.bladeUserId = this.$store.state.userInfo.MJUserId;
            resolve(ruleForm);
          } else {
            reject("error submit!!");
            return false;
          }
        });
      });
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangeDetails(e) {
      this.isAssociationDialog = false;
      this.ruleForm.policyInfoList.push(...e);
    },
    handleDelPolicy(ind, tiem, key) {
      
      this.$confirm(
        `确认移除该${key == "customerList" ? "客户" : "用户"}?`,
        "解绑",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (this.detailData && this.detailData.id) {
            let delApi =
              key == "customerList" ? delProjectCustomer : delViewUser;
            delApi({
              projectId: this.detailData.id,
              dataId: tiem.id,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "移除成功!",
                });
                this.$refs[
                  key == "customerList" ? "customer" : "bladeUser"
                ].refresh();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "移除失败",
                });
              });
          } else {
            let index = this.ruleForm[key].findIndex(vv=> vv.id == tiem.id);
            this.ruleForm[key].splice(index, 1);
            this.$message({
              type: "success",
              message: "移除成功!",
            });
            this.$forceUpdate();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移除",
          });
        });
    },
    handleAddRisk(e) {
      this.isAddRiskDialog = false;
      if (this.ruleForm.riskInfoList && this.ruleForm.riskInfoList.length) {
        if (this.riskEditData && this.riskEditData.riskType) {
          let ind = this.ruleForm.riskInfoList.findIndex(
            (val) => JSON.stringify(val) == JSON.stringify(this.riskEditData)
          );
          Object.assign(this.ruleForm.riskInfoList[ind], e);
          // this.ruleForm.riskInfoList[ind] = e;
          this.$forceUpdate();
        } else {
          this.ruleForm.riskInfoList.push(e);
        }
      } else {
        this.ruleForm.riskInfoList = [e];
      }
    },
    handleShowAddRisk() {
      if (!this.ruleForm.id) {
        this.$message({
          type: "error",
          message: "添加项目不能添加项目风控!",
        });
        return false;
      }
      this.riskEditData = {};
      this.isAddRiskDialog = true;
    },
    handleChangeSalesman(id, name, nodeData) {
      this.ruleForm.leaderDeptName = nodeData.parentName;
      this.ruleForm.leaderDeptId = nodeData.parentId;
      this.ruleForm.projectLeader = name;
      this.$nextTick(()=>{
        this.$refs.ruleForm.validateField("projectLeaderId");
      })
    },
    changeSelect(e, list, key, item) {
      let value = list.find((val) => val.dictValue == e);
      item[key] = value.dictKey;
    },
    handleReview(item) {
      this.trid = item.id;
    },
    async handleAddPolicyProject(e) {
      if (e) {
        if (!e.length) {
          this.ruleForm.policyProjectIds = [];
          this.ruleForm.policyProjectNames = [];
          this.ruleForm.policyInfoList = [];
          this.isAssociationDialog = false;
        }
        if (this.detailData && this.detailData.id) {
          let { data } = await bindPolicyProject({
            dataId: e,
            projectId: this.detailData.id,
          });
          // this.isAssociationDialog = false;
          // return false;
          this.$refs.policy.refresh();
        }else{
          let res = await projectPolicyList({ policyProjectId: e });
          console.log(res);
          this.ruleForm.policyInfoList = res.data;
        }
        // let { data } = await projectPolicyList({ policyProjectId: e });
        this.isAssociationDialog = false;
        this.ruleForm.policyProjectIds = e.split(",");
        // this.ruleForm.policyInfoList = data;
        // this.$refs.policy.refresh();
      }else {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindPolicyProject({
            projectId: this.detailData.id,
          });
        }
        this.ruleForm.policyProjectIds = [];
        this.ruleForm.policyProjectNames = [];
        this.ruleForm.policyInfoList = null;
        this.isAssociationDialog = false;
          this.$refs.policy.refresh();
      }
    },
    async handleAddGroup(e) {
      if (e && e.length) {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindVehicleGroup({
            dataId: e.map((val) => val.id).toString(),
            projectId: this.detailData.id,
          });
          // this.isGroupDialog = false;
          // this.$refs.vehicle.refresh();
          // return false;
        }
        this.ruleForm.vehicleGroupIds = e.map((val) => val.id);
        this.ruleForm.vehicleGroupNames = e.map((val) => val.label);
        let { data } = await projectDeviceList({
          vehicleGroupIds: e.map((val) => val.id).toString(),
            projectId: this.detailData.id,
        });
        this.isGroupDialog = false;
        this.ruleForm.vehicleList = data;
      }else {
        if (this.detailData && this.detailData.id) {
          let { data } = await bindVehicleGroup({
            dataId: '',
            projectId: this.detailData.id,
          });
        }
        this.ruleForm.vehicleGroupIds = [];
        this.ruleForm.vehicleGroupNames = [];
        this.ruleForm.vehicleList = [];
        this.isGroupDialog = false;
        this.$refs.vehicle.refresh();
      }
    },
    async handleAddCustomers(e) {
      this.ruleForm.customerId = e.id;
      this.ruleForm.customer = e.account;
    },
    async handleAddUser(e) {
      this.ruleForm.bladeUserList = e;
      this.isAddUserDialog = false;
      if (this.detailData && this.detailData.id) {
        let { data } = await addViewUser({
          dataId: e.map((val) => val.id).toString(),
          projectId: this.detailData.id,
        });
        this.$refs.bladeUser.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.container-box {
  padding-top: 18px;
  border-top: 2px dashed #dedede;
  .personnel_repetition {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;
    padding: 8px 12px;
    margin-bottom: 24px;
    background: #cce6ff;
    border-radius: 8px;
    &_details {
      color: #0080ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .yili-button-primary {
    background: #4278c9;
    border: 1px solid #4278c9;
    margin-bottom: 16px;
  }
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .merge-date {
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .merge-box {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 3px 10px;
    > span {
      display: block;
      text-align: center;
      width: 20px;
    }
    .merge-date {
      flex: 1;
      text-align: center;
      ::v-deep .el-input__inner {
        padding: 0;
        text-align: center;
        border: none;
      }
      ::v-deep .el-input__prefix {
        display: none;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-autocomplete {
    width: 100%;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  ::v-deep .el-form-item {
    width: 30%;
    .el-form-item__content {
      width: 70%;
    }
  }
  @media (max-width: 1500px) {
    ::v-deep .el-form-item {
      width: 45%;
      .el-form-item__content {
        width: 85%;
      }
    }
  }
  @media (max-width: 1000px) {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 90%;
      }
    }
  }
  .plan-table {
    display: column;
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      .el-icon--right {
        margin-left: 0;
      }
    }
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
  .input-customization {
    ::v-deep .el-input__inner {
      padding-right: 72px;
    }
  }
  .certificate-label {
    ::v-deep .el-form-item__label {
      width: 70%;
    }
    .certificate-box {
      display: flex;
      justify-content: space-between;
      &-right {
        display: flex;
        align-items: center;
        .example {
          font-size: 12px;
          color: #0080ff;
          margin-right: 8px;
          cursor: pointer;
        }
        .label-button {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          padding: 0 6px;
          color: #ffaa00;
          background: rgba(255, 170, 0, 0.16);
          border-radius: 4px;
          border: 1px solid #ffaa00;
          cursor: pointer;
        }
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .insurance-clause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 1000px;
    color: #00bc0d;
    font-size: 14px;
    border-top: none;
    border: 1px solid #ebeef5;
    cursor: pointer;
  }
  .input-position {
    .input-suffix {
      position: relative;
      width: 44px;
      height: 100%;
      border-left: 1px solid #dcdfe6;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 48px;
    }
  }
}
.clause-title {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.list-item {
  &:first-child {
    border: none;
    padding-top: 0;
  }
  padding-top: 10px;
  border-top: 1px dashed #999;

  position: relative;
  .del-button {
    position: absolute;
    right: 0;
  }
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
.distpicker-all-box select {
  width: 30%;
}
.hide-picker label:nth-of-type(1) {
  display: none;
}
.popper-customization {
  background: rgba(255, 170, 0, 0.16) !important;
  color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
  // .popper__arrow::after {
  //   border-top-color: #747574;
  // }
}
.popper-customization[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ffaa00 !important;
}
.popper-customization[x-placement^="top"] .popper__arrow {
  border-top-color: #ffaa00 !important;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
