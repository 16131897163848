<template>
  <el-dialog title="选择保单"
    :visible.sync="dialogVisible"
    custom-class="select-policy-dialog"
    width="980px"
    :before-close="handleClose">
    <div>
      <div class="form-box">
        <el-button icon="el-icon-refresh" style="color:#4278C9;border:1px solid #4278C9;margin-right:12px;" plain @click="getTableData"></el-button>
        <el-input @input="handleInput"
          placeholder="输入保单号、被保险人"
          prefix-icon="el-icon-search"
          v-model="formInline.policyNo">
        </el-input>
      </div>
      <!-- <el-form :inline="true"
        :model="formInline"
        class="demo-form-inline">
        <el-form-item label="保单号">
          <el-input v-model="formInline.policyNo"
            placeholder="请输入保单号"></el-input>
        </el-form-item>
        <el-form-item label="投保人">
          <el-input v-model="formInline.policyHolder"
            placeholder="请输入投保人"></el-input>
        </el-form-item>
        <el-form-item label="被保险人">
          <el-input v-model="formInline.insured"
            placeholder="请输入被保险人"></el-input>
        </el-form-item>
        <el-form-item label="车架号">
          <el-input v-model="formInline.frameNo"
            placeholder="请输入车架号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
            @click="current = 1,getTableData()">查询</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-form-item>
      </el-form> -->
      <el-table :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" :selectable="handleFilterSelection"
          width="55"></el-table-column>
        <el-table-column type="index"
          label="序号"
          width="50"></el-table-column>
        <el-table-column prop="policyNo"
          label="保单号" width="200">
          <template slot-scope="{row}">
            <div class="el-button polecy-button el-button--text el-button--small"
              style="text-decoration:underline;padding: 0;"
              @click="handleGoPath(row)">{{ row.policyNo }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="insured" width="116"
          label="被保险人"></el-table-column>
        <el-table-column prop="insuranceCompanyName" width="176"
          label="保险公司"></el-table-column>
        <el-table-column prop="deviceTypeName" width="180"
          label="设备类型">
          <template
            slot-scope="{ row }">
            {{ row.isSingle !== false ? row.deviceTypeName : '多标的类型，详细查看详情' }}
            </template>
         </el-table-column>
        <el-table-column prop="frameNo" width="220px"
          label="车架号">
          <template
            slot-scope="{ row }">
            {{ row.isSingle !== false ? row.frameNo : '多标的类型，详细查看详情' }}
            </template>
         </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { policyList, getApprovalPolicyDetail } from "@/api/policy";
export default {
  props: {
    showDialog: Boolean,
    excludePolicyId: {
      type: Array,
      default: ()=> []
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            policyNo: '',
            policyHolder: '',
            insured: '',
            frameNo: ''
          };
          this.policyNo = "";
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      },
      policyNo: '',
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {},
      timeObj: null,
    };
  },
  methods: {
    getTableData(e) {
      let formInline = {};
      if (e && (e == 'current' || e == 'size')) {
        formInline = JSON.parse(JSON.stringify(this.oldFormInline));
      } else {
        this.oldFormInline = JSON.parse(JSON.stringify(this.formInline));
        formInline = JSON.parse(JSON.stringify(this.formInline));
      }
      policyList({ ...formInline, current: this.current, size: this.size, category: this.$route.meta.policyType }).then(res => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleInput() {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(() => {
        clearTimeout(this.timeObj);
        this.formInline.current = 1;
        this.getTableData();
      }, 500);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    onReset() {
      this.current = 1;
      this.policyNo = "";
      this.formInline = {
        policyNo: '',
        policyHolder: '',
        insured: '',
        frameNo: ''
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData('current');
    },
    handleSizeChange(e) {
      this.size = e;
      this.getTableData('size');
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error('请选择保单');
        return false;
      }
      this.$emit('submit', this.fileList);
    },
    handleSelectionChange(e) {
      this.fileList = e;
    },
    handleGoPath(e) {
      this.$router.push({ path: '/routerPolicyManagement/ConstructionMachinery/policyDetails/' + e.id });
    },
    handleFilterSelection(e) {
      return !(this.excludePolicyId && this.excludePolicyId.length ? this.excludePolicyId.map(val=> val.policyNo).includes(e.policyNo) : false);
    }
  }
};
</script>

<style lang="scss">
.select-policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  .form-box {
    display: flex;
    margin: 16px 0;
  }
}
</style>