<template>
  <div v-loading="loading">
    <el-table :data="tableList" border style="width: 100%">
      <slot></slot>
    </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationData.index"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="paginationData.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableApi: {
      type: Function,
      default: null,
    },
    tableType:  {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: () => {},
    },
    isTraditional: {
      type: Boolean,
      default: false,
    },
    traditionalData: {
      type: Object,
      default: () => {},
    },
    isUseApi: {
      type: Boolean,
      default: true,
    },
    interfaceData: {
      type: [Array, String],
      default: () => [],
    }
  },
  watch: {
    tableData: {
      handler(v) {
        if (!this.formData || !this.formData.id || this.isTraditional) {
          this.isNoApi = true;
          this.paginationData.index = 1;
          this.total = v.length || 0;
          this.tableList = JSON.parse(JSON.stringify(v)).splice(((this.paginationData.index - 1) * this.paginationData.size || 1) - 1, this.paginationData.size);
        }
      }
    },
    formData: {
      handler(v) {
        if (v && v.id) {
          this.getList();
        }
      }
    },
    'paginationData.size': {
      handler(v) {
        if ((!this.formData || !this.formData.id) && this.tableData && this.tableData.length || (this.isTraditional && this.isNoApi)) {
          this.tableList = JSON.parse(JSON.stringify(this.tableData)).splice(((this.paginationData.index - 1) * this.paginationData.size || 0), this.paginationData.size);
        }else {
          this.getList();
        }
      }
    },
    'paginationData.index': {
      handler(v) {
        if ((!this.formData || !this.formData.id) && this.tableData && this.tableData.length || (this.isTraditional && this.isNoApi)) {
          this.tableList = JSON.parse(JSON.stringify(this.tableData)).splice(((this.paginationData.index - 1) * this.paginationData.size || 0), this.paginationData.size);
        }else {
          this.getList();
        }
      }
    },
    interfaceData: {
      handler(v) {
        this.paginationData.index = 1;
      },
    }
  },
  data() {
    return {
      paginationData: {
        size: 10,
        index: 1,
      },
      total: 0,
      tableList: [],
      loading: false,
      isNoApi: false
    };
  },
  methods: {
    handleSizeChange(e) {
      this.paginationData.size = e;
      if (this.paginationData.index * this.paginationData.size > this.total) {
        this.paginationData.index = Math.ceil(this.total / e);
      };

    },
    handleCurrentChange(e) {
      this.paginationData.index = e;
    },
    refresh() {
      if (this.formData && this.formData.id) {
        this.getList();
      }
    },
    async getList() {
      if (!this.isUseApi) {
        return false;
      }
      this.loading = true;
      let apiFrom = {
        ...this.paginationData,
        projectId: this.formData.id,
          ...this.traditionalData,
      };
      if (this.tableType == 'policy') {
        apiFrom.policyProjectId = this.formData.policyProjectIds.toString() || '';
      }
      if (this.tableType == 'vehicle') {
        // apiFrom.deviceIds = this.formData.policyProjectIds || [];
      }
      if (this.isTraditional) {
        apiFrom = {
          pageSize: 999999999,
          pageIndex: this.paginationData.index,
          projectId: this.formData.id,
          ...this.traditionalData,
          ...this.paginationData
        };
      }
      // if (this.tableType == 'customer') {
      //   apiFrom.query = this.paginationData;
      // }
      const {data, code} = await this.tableApi(apiFrom);
      this.loading = false;
      this.isNoApi = false;
      // if (this.tableType == 'policy' && code == 200 && data && data.length) {
      //   this.tableList = data
      // }else 
      if (!this.isTraditional && code == 200 && data && data.records && data.records.length) {
        this.tableList = data.records;
        this.total = data.total || 0;
      }else if (this.isTraditional && code == 200 && data && data['deviceInfoList'] && data['deviceInfoList'].records && data['deviceInfoList'].records.length) {
        // this.tableList = data['deviceInfoList'].records;
        this.$emit('changeList', data['deviceInfoList'].records)
        this.total = data['deviceInfoList'].total || 0;
      }else if (this.isTraditional && code == 200 && data && data.records && data.records.length) {
        // this.$emit('changeList', data.records)
        this.tableList = data.records;
        this.total = data.total || 0;
      }else {
        this.tableList = [];
        this.total = 0;
      }
    }
  }
};
</script>

<style>
</style>