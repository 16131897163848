<template>
  <el-dialog :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog"
    destroy-on-close title="绑定设备"
    width="616px">
    <div>
      <SelectGroupTree :selecteds="groupsList" isShowDisabledName :projectId="projectId" isDestruction :multipleProp="{groupCheckBox: true}" @on-confirm="handleCheck" placeholder="请选择" >
        <el-button
                type="primary"
                size="mini"
                class="yili-button-primary"
                >添加车组</el-button
              >
      </SelectGroupTree>
      <div class="pool">
        <div class="pool-item" v-for="item,index in policyProjectNameList" :key="index">
          <div>{{ item }}</div>
          <i class="pool-item-icon el-icon-close" @click="handleDel(index)"></i>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import SelectGroupTree from "@/components/GroupTree/NewSelectGroupTree";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    idsList: {
      type: Array,
      default: ()=> []
    },
    nameList: {
      type: Array,
      default: ()=> []
    },
    projectId:  {
      type: [String, Number],
      default: ''
    },
  },
  components: { SelectGroupTree },
  data() {
    return {
      dialogVisible: false,
      groupsList: [],
      policyProjectNameList: []
    };
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }
    },
    idsList: {
      handler(v) {
        if (v && v.length) {
          this.groupsList = v.map((val,ind)=> {
            return { 
              label: this.nameList[ind],
              id: val
            }
          });
          this.policyProjectNameList = this.nameList;
        }else {
          this.groupsList = [];
        }
      }
    }
  },
  mounted() { },
  methods: {
    reset() {
        this.clearClose();
    },
    confirm() {
      this.$emit('submit', this.groupsList);
    },
    clearClose() {
      this.dialogVisible = false;
    },
    handleCheck(e,name) {
      this.policyProjectNameList = e.map(val=> val.groupName);
      this.groupsList = e.map(val=> {
        return { 
          label: val.groupName,
          id: val.groupId
        }
      });
    },
    handleDel(ind) {
      this.groupsList.splice(ind, 1);
      this.policyProjectNameList.splice(ind, 1);
    }
    // input_num(e, tiem, key, max, decimal = 2) {
    //   inputNum(e, tiem, key, max, decimal);
    // },
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
  .pool {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 144px;
    padding: 8px;
    padding-left: 0;
      margin-top: 8px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DEE3E6;
    &-item {
      position: relative;
      height: 32px;
      line-height: 32px;
      padding: 0 30px 0 12px;
      margin-left: 8px;
      margin-bottom: 8px;
      background: #F5F5F5;
      border-radius: 4px;
      &-icon{
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
