<template>
  <el-popover
    :disabled="disabled"
    :placement="placement"
    width="574"
    ref="popoverbox"
    trigger="click"
    @show="handleChange"
  >
    <div class="mian-container">
      <div class="transfer">
        <div class="transfer-left">
          <!-- 员工搜索 -->
          <div>
            <el-input
              placeholder="搜索项目"
              prefix-icon="el-icon-search"
              v-model="filterText"
            />
          </div>

          <!-- 树形结构 -->
          <div v-if="isTree">
            <el-tree
              show-checkbox
              :data="treeData" :check-strictly="checkStrictly"
              default-expand-all
              node-key="id"
              :props="defaultProps"
              ref="structureTree"
              @check="selectNode"
              :filter-node-method="filterNode"
            >
              <span slot-scope="{ node, data }">
                <span style="font-size: 14px;" :style="{ color: data.readOnlyNode ? '#CCCCCC' : '#333' }">
                  {{ node.label }}
                </span>
                <span v-if="data.readOnlyNode" style="font-size: 14px;color:#ccc">（已绑:{{ data.readOnlyProjectNames }}）</span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mian-container el-select"
      style="display: block; height: 32px"
      slot="reference"
    >
        <slot>
      <div
        :class="{ 'el-input__inner': true, 'disabled-s': disabled }"
        style="height: 32px"
      >
          <div
            style="
              white-space: nowrap;
              overflow: hidden;
              height: 32px;
              line-height: 32px;
            "
            :class="{ 'placeholder-style': !selectName }"
          >
            {{ selectName || placeholder }}
          </div>
      </div>
        </slot>
    </div>
    <div class="bottom-btn" v-if="isMulti">
      <el-button @click="closePopover" type="primary">确定</el-button>
    </div>
  </el-popover>
</template>

<script>
import {
  pageListToSelect
} from "@/api/basicData";
export default {
  name: "PolicyProjectSelect",
  props: {
    value: [String, Array, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    isUseName: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    highestLevel: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    placeholder: {
      type: String,
      default: "",
    },
    projectId:  {
      type: [String, Number],
      default: ''
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    value: {
      handler(v) {
        if (this.treeData && this.treeData.length) {
          if (!this.isMulti) {
            this.isUseName ? (this.selectName = v) : (this.selectName = "");
          }
          this.$nextTick(() => {
            this.handleInitEcho(this.treeData);
          });
        }
        if (!v) {
          this.selectName = "";
        }
        // if (v instanceof Array) {
        //   this.$nextTick(() => {
        //     this.$refs.structureTree.setCheckedKeys(v);
        //   });
        // } else {
        //   this.$nextTick(() => {
        //     this.$refs.structureTree.setCheckedKeys([v]);
        //   });
        // }
      },
    },
    selectName: {
      handler(v) {
        this.$emit('changeName', v);
      },
    },
    filterText(value) {
      this.$refs.structureTree.filter(value);
    },
  },
  data() {
    return {
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "subProjectList",
        label: "projectName",
        disabled: "readOnlyNode",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      selectName: "",
      isTree: true,
    };
  },
  created() {
    this.getTreeData();
  },
  methods: {
    async getTreeData() {
      let treeData = await pageListToSelect({
        projectId: this.projectId || -1,
        bladeUserId: this.$store.state.userInfo.MJUserId,
      });
      if (treeData.data && treeData.data.length) {
        this.treeData = treeData.data;
        if (this.value) {
          this.$nextTick(() => {
            this.handleInitEcho(this.treeData);
          });
        }
      }else {
        this.treeData = [];
      }
    },
    handleInitEcho(e) {
      e.map((val) => {
        if (this.isMulti && this.value) {
          const valueList = Array.isArray(this.value) ? this.value : this.value.split(',');
          if (
            valueList.includes(val.id) &&
            !this.selectName.includes(val[this.defaultProps.label])
            ) {
              if (this.highestLevel) {
                if (this.selectName) {
                  this.selectName += "," + val[this.defaultProps.label] + (valueList.includes(val.parentId) ? `&&--${val.parentId}-` : '') ;
                } else {
                  this.selectName += val[this.defaultProps.label] + (valueList.includes(val.parentId) ? `&&--${val.parentId}-` : '');
                }
              }else {
                if (
                  this.selectName.split(",").length === valueList.length
                ) {
                  this.selectName += val[this.defaultProps.label];
                } else {
                  this.selectName += val[this.defaultProps.label] + ",";
                }
              }
          }
        } else {
          if (val.id == this.value) {
            this.selectName = val[this.defaultProps.label];
          } else if (this.isUseName && val[this.defaultProps.label] == this.value) {
            this.selectName = val[this.defaultProps.label];
          }
        }
        if (this.isMulti && val[this.defaultProps.children] && val[this.defaultProps.children].length) {
          this.handleInitEcho(val[this.defaultProps.children]);
        }else if (!this.selectName && val[this.defaultProps.children] && val[this.defaultProps.children].length) {
          this.handleInitEcho(val[this.defaultProps.children]);
        }
      });
      if (this.isMulti) {
      }
    },
    selectNode(data, { checkedNodes }) {
        let keys = this.$refs.structureTree.getCheckedKeys();
        let nodeIds = [];
        checkedNodes.forEach((node) => {
            nodeIds.push(node.id);
        });
        let ids = Array.from(new Set(nodeIds));
        if (keys && keys.length) {
          let names = [];
          if (this.isMulti) {
            this.$refs.structureTree.setCheckedKeys(ids);
            checkedNodes.forEach((item) => {
              if (this.highestLevel) {
                names.push(item[this.defaultProps.label] + (nodeIds.includes(item.parentId) ? `&&--${item.parentId}` : ''));
              }else{
                names.push(item[this.defaultProps.label] + (nodeIds.includes(item.parentId) ? `&&--${item.parentId}` : ''));
              }
            });
            var newNames = names.filter((item, index) => {
              return names.indexOf(item) === index;
            });
            this.selectName = newNames.toString();
          } else {
            this.$refs.structureTree.setCheckedKeys([data.id]);
            this.selectName = data[this.defaultProps.label];
          }
          if (this.isMulti) {
            this.isUseName
              ? this.$emit("change", names.toString(), names.toString())
              : this.$emit("change", ids.toString(), names.toString());
          } else {
            this.isUseName
              ? this.$emit("change", data[this.defaultProps.label], data[this.defaultProps.label])
              : this.$emit("change", data.id, data[this.defaultProps.label]);
          }
          this.$emit("changeChecked", data);
          this.$forceUpdate();
          if (!this.isMulti) {
            this.$refs.popoverbox.doClose();
          }
        } else {
          this.selectName = "";
          this.$emit("change", '', '')
        }
    },
    handleChange(e) {
      this.filterText = "";
      this.isTree = false;
      if (this.value) {
        this.$nextTick(() => {
          this.isTree = true;
          if (this.value instanceof Array) {
            this.$nextTick(() => {
              this.$refs.structureTree.setCheckedKeys(this.value);
            });
          } else {
            this.$nextTick(() => {
              this.$refs.structureTree.setCheckedKeys(
                this.value?.split(",") || ""
              );
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.isTree = true;
        });
        this.$refs.structureTree.setCheckedKeys([]);
      }
    },
    closePopover() {
      this.$refs.structureTree.setCheckedKeys([]);
      this.$refs.popoverbox.doClose();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
.title {
  display: flex;
  justify-content: left;
  color: #ffffff;
}
.mian-container {
  display: column;
  max-height: 400px;
  overflow: auto;
  .header {
    .header-search {
      width: 100%;
    }
  }
  .placeholder-style {
    color: #ccc;
  }
  .transfer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .middle-icon {
      color: #4278c9ff;
      align-self: center;
      font-size: 20px;
      transform: rotate(90deg);
    }
    > div {
      padding: 10px;
      margin: 0 5px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #cccccc;
    }
    .transfer-left {
      flex: 1;
      .left-top {
        margin-bottom: 5px;
      }
    }
    .transfer-right {
      display: column;
      flex: 1;
      .right-top {
        .clear {
          color: #4278c9ff;
        }
      }
      .select-list {
        display: column;
        margin: 5px 0;
        .select-item {
          display: flex;
          padding: 5px;
          justify-content: space-between;
          align-items: center;
          .el-icon-circle-close {
            display: none;
          }
        }
        .select-item:hover {
          background: #bbcfee;
          border-radius: 4px;
          .el-icon-circle-close {
            display: inline;
          }
        }
      }
    }
  }
  .transfer-top {
    display: flex;
    justify-content: space-between;
    background-color: #ffffffff;
    padding: 5px;
    border-radius: 5px;
  }
  .text-top {
    > span {
      margin: 0 5px;
    }
  }
}
.el-select {
  overflow: hidden;
}
.disabled-s {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
.bottom-btn {
  margin-right: 10px;
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}
</style>
