<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog"
    destroy-on-close
    title="添加用户"
    width="969px"
  >
    <div>
      <div class="form-box">
        <div class="form-box" style="margin-bottom: 0;align-items: center;" v-if="$store.state.tenantId == '000000'">
          <div style="font-size: 14px;color: #333333;margin-right:12px;">租户名称</div>
            <el-select
              v-model="formData.tenantId"
              style="width: 380px"
              filterable @change="getList"
              placeholder="请选择"
            >
              <el-option
                v-for="item in tenantsList"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </div>
        
        <span v-else></span>
        <div>
          <el-input
            placeholder="搜索登录账号、昵称、姓名、手机号"
            prefix-icon="el-icon-search"
            style="width: 330px; margin-right: 10px"
            @input="handleInput"
            v-model="formData.searchKeyWord"
          >
          </el-input>
          <!-- <el-button
            type="primary"
            icon="el-icon-refresh"
            size="small"
            @click="getList"
          ></el-button> -->
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData" @select-all="handleSelectAll" @select="handleSelect"
        highlight-current-row height="400px"
        @selection-change="handleCurrentChangeRow"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column property="account" label="登录账号">
        </el-table-column>
        <el-table-column property="nickname" label="昵称">
        </el-table-column>
        <el-table-column property="name" label="姓名">
        </el-table-column>
        <el-table-column property="phone" label="手机号"> </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div slot="footer">
      <el-button @click="dialogVisible = false">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProjectUserList } from "@/api/basicData"
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    defaultList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
      formData: {
        index: 1,
        size: 10,
        searchKeyWord: "",
        tenantId: this.$store.state.tenantId
      },
      total: 0,
      tableData: [],
      currentRow: [],
      tenantsList: [],
      overallRowList: [],
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    defaultList: {
      handler(v) {
        if (v && v.length) {
          this.overallRowList = JSON.parse(JSON.stringify(v));
        }else  {
          this.overallRowList = [];
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formData = {
            index: 1,
            size: 10,
            searchKeyWord: "",
            tenantId: this.$store.state.tenantId
          }
          this.getList();
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  mounted() {
    if (this.$store.state.tenantId == '000000') {
      this.tenantsList = JSON.parse(JSON.stringify(this.$store.state.tenantList));
      this.tenantsList.splice(0, 1);
    }
  },
  methods: {
    reset() {
      this.clearClose();
    },
    confirm() {
      this.$emit("submit", this.overallRowList);
    },
    clearClose() {
      this.dialogVisible = false;
    },
    handleCheck(id, name) {
      this.policyProjectNameList = name.split(",");
    },
    handleCurrentChangeRow(val) {
      //TODO 改多选
      this.currentRow = val;
    },
    handleSizeChange(val) {
      this.formData.size = val;
      if (this.formData.index * this.formData.size > this.total) {
        this.formData.index = Math.ceil(this.total / val);
      };
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.index = val;
      this.getList();
    },
    handleInput() {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(() => {
        clearTimeout(this.timeObj);
        this.formData.index = 1;
        this.getList();
      }, 500);
    },
    async getList() {
      const {data,code} = await getProjectUserList(this.formData);
      if (code == 200 && data && data.records) {
        this.tableData = data.records;
        this.$nextTick(()=>{
          if (this.overallRowList && this.overallRowList.length) {
          const idsList = this.overallRowList.map(v=> v.id);
            const rows = data.records.filter(v => idsList.includes(v.id));
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
          }
        })
        this.total = data.total || 0;
      }
    },
    handleSelectAll(e) {
      if (e && e.length) {
        e.forEach(vv=> {
          const idsList = this.overallRowList.map(v=> v.id);
          const ind = idsList.includes(vv.id);
          if (!ind) {
            this.overallRowList.push(vv);
          }
        });
      }else {
        this.tableData.forEach(vv=> {
          const ind = this.overallRowList.findIndex(val => val.id == vv.id);
          if (ind > -1) {
            this.overallRowList.splice(ind, 1);
          }
        });
      }
    },
    handleSelect(list,e) {
      const ind = this.overallRowList.findIndex(val => val.id == e.id);
      if (ind < 0) {
        this.overallRowList.push(e);
      }else {
        this.overallRowList.splice(ind, 1);
      }
    }
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }

  .form-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
