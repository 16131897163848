<template>
  <div class="project-target">
    <el-tabs type="border-card"  @tab-click="handleClick">
      <!-- <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 我的行程</span>
        我的行程
      </el-tab-pane> -->
      <el-tab-pane :label="item.year + ''" v-for="item,index in targetList" :key="index">
        <TargetForm :readOnly="readOnly" :children="children" :compatibleReadOnly="compatibleReadOnly" v-model="targetList[index]" :tabInd="index" />
        <!-- <TargetForm v-if="!$route.query.id" :readOnly="readOnly" :children="children" :compatibleReadOnly="compatibleReadOnly" v-model="targetList[index]" :tabInd="index" />
        <TargetForm v-else v-loading="loading" :readOnly="readOnly" :children="children" :compatibleReadOnly="compatibleReadOnly" v-model="detailData" :tabInd="index" /> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TargetForm from "@/views/routerProjectManagement/components/ProjectList/components/targetForm.vue";
import { projectTargetInfo } from "@/api/basicData";
export default {
  model: {
    prop: 'targetList',
    event: 'change'
  },
  props: {
    targetList: {
      type: [Object,Array],
      default: ()=> {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    compatibleReadOnly: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabsList: [],
      detailData: {},
      loading: false,
    }
  },
  components: {
    TargetForm
  },
  created() {
    this.$route.query && this.$route.query.id ? this.getDetails('2024') : "";
  },
  methods: {
    handleClick(e) {
      // this.$route.query && this.$route.query.id ? this.getDetails(e.label) : '';
    },
    getDetails(e) {
      this.loading = true;
      // , year: e
      projectTargetInfo({id: this.$route.query.id})
        .then((res) => {
          this.loading = false;
          // this.detailData = res.data;
          this.$emit('change', res.data)
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.project-target {
  ::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
    .el-tabs__content {
      border: 1px solid #DEE3E6;
    }
   .el-tabs__header {
      width: initial !important;
      background-color: transparent;
      border-bottom: none;
      margin-bottom: -2px;
      // background-color: #F5F7FA;
      // border-bottom: 1px solid #E4E7ED;
      .el-tabs__item {
        margin-right: 6px;
        border: 1px solid #DEE3E6;
        background: #F2F7FD;
      }
      .is-active {
        border-bottom: none;
        background: #fff;
      }
      .el-tabs__nav {
        border: 1px solid transparent;
      }
    }
  }
} 

</style>