import { render, staticRenderFns } from "./addCustomersDialog.vue?vue&type=template&id=f8276338&scoped=true"
import script from "./addCustomersDialog.vue?vue&type=script&lang=js"
export * from "./addCustomersDialog.vue?vue&type=script&lang=js"
import style0 from "./addCustomersDialog.vue?vue&type=style&index=0&id=f8276338&prod&lang=scss&scoped=true"
import style1 from "./addCustomersDialog.vue?vue&type=style&index=1&id=f8276338&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8276338",
  null
  
)

export default component.exports